import React from "react"
import "../styles/slick.min.css"
import "../styles/slick-theme.min.css"
import "lazysizes"
import { Link } from "gatsby"
class SimpleSlider extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="featureon col-md-2 col-6">
          <Link to="/press" title="We’re famous!">
            <img
              data-src="https://assets.gofloaters.com/featureon/forbes.jpg"
              className="lazyload"
              width="155"
              height="92"
              alt="Forbes"
            ></img>
          </Link>
        </div>
        <div className="featureon col-md-2 col-6">
          <Link to="/press" title="We’re famous!">
            <img
              data-src="https://assets.gofloaters.com/featureon/yourstory.jpg"
              className="lazyload"
              width="155"
              height="91"
              alt="YourStory"
            ></img>
          </Link>
        </div>
        <div className="featureon col-md-2 col-6">
          <Link to="/press" title="We’re famous!">
            <img
              data-src="https://assets.gofloaters.com/featureon/entrepreneurindia.jpg"
              className="lazyload"
              width="155"
              height="82"
              alt="entrepreneur"
            ></img>
          </Link>
        </div>
        <div className="featureon col-md-2 col-6">
          <Link to="/press" title="We’re famous!">
            <img
              data-src="https://assets.gofloaters.com/featureon/thehindu.jpg"
              className="lazyload"
              width="155"
              height="95"
              alt="The Hindu"
            ></img>
          </Link>
        </div>

        <div className="featureon col-md-2 col-6">
          <Link to="/press" title="We’re famous!">
            <img
              data-src="https://assets.gofloaters.com/featureon/startup-story.jpg"
              className="lazyload"
              width="155"
              height="92"
              alt="Startup Story"
            ></img>
          </Link>
        </div>

        <div className="featureon col-md-2 col-6">
          <Link to="/press" title="We’re famous!">
            <img
              data-src="https://assets.gofloaters.com/featureon/timesofindia.png"
              className="lazyload"
              width="155"
              height="96"
              alt="times of india"
            ></img>
          </Link>
        </div>
      </div>
    )
  }
}
export default SimpleSlider
